<template>
  <div class="customerManageForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="config"
      :form="form"
      @update="update"
    >
      <col2-detail :span="24">
        <col2-block title="客户基本信息">
          <col2-item :span="24">
            <el-form-item label="客户名称" prop="name"
              :rules="[{required: true, message: '请输入客户名称', trigger: 'blur'}]"
            >
              <v-input
                v-model="form.name"
                placeholder="请输入客户名称"
                :disabled="isEdit"
                :maxlength="30"
                :width="width2"
              />
            </el-form-item>
          </col2-item>
          <col2-item :span="24">
            <col2-item class="f-wh">
              <el-form-item label="客户类型" prop="type"
                :rules="[{required: true, message: '请选择客户类型', trigger: 'change'}]"
              >
                <v-select v-model="form.type" :options="customerTypeOps" :width="width"/>
              </el-form-item>
            </col2-item>
            <col2-item class="f-wh">
              <el-form-item label="客户编号" prop="number"
                :rules="[{required: validateNum, message: '请输入客户编号', trigger: 'blur'}]"
              >
                <v-input
                  v-model="form.number"
                  placeholder="请输入客户编号"
                  :maxlength="20"
                  :width="width"
                />
              </el-form-item>
            </col2-item>
          </col2-item>
          <col2-item :span="24">
            <col2-item class="f-wh">
              <el-form-item label="邮箱地址" prop="mail"
                :rules="[ { validator: validateEmail, trigger: 'blur' } ]"
              >
                <v-input
                  v-model="form.mail"
                  placeholder="请输入邮箱地址"
                  :maxlength="30"
                  :width="width"
                />
              </el-form-item>
            </col2-item>
            <col2-item class="f-wh">
              <el-form-item label="邮政编码" prop="postCode"
                :rules="[ { validator: validateCode, trigger: 'blur' } ]"
              >
                <v-input
                  v-model="form.postCode"
                  placeholder="请输入邮政编码"
                  :maxlength="6"
                  :width="width"
                />
              </el-form-item>
            </col2-item>
          </col2-item>
          <col2-item :span="24">
            <div style="display: flex;">
              <el-form-item label="联系地址" prop="area">
                <area-select
                  :codes.sync="areaList"
                  :detail-addr="false"
                  @change="areaChange"
                />
              </el-form-item>
              <el-form-item label-width="20px" prop="address">
                <v-input
                  v-model="form.address"
                  placeholder="请输入详细地址"
                  :maxlength="30"
                  :width="340"
                />
              </el-form-item>
            </div>
          </col2-item>
          <col2-item :span="24">
            <el-form-item label="客户简介" prop="introduction">
              <v-textarea v-model="form.introduction" :width="width2" :maxlength="100" />
            </el-form-item>
          </col2-item>
          <col2-item style="width:720px">
            <el-form-item label="附件" prop="fileUrlList">
              <v-uploader2
                :action="uploadURL"
                filePath="customerFiles"
                v-model="form.fileUrlList"
                tip="可以添加客户相关资料文件，最多允许上传10个文件,单个文件大小不允许超过50MB"
                :limit="10"
                :beforeRemove="beforeRemove"
              />
            </el-form-item>
          </col2-item>
          <col2-item :span="24">
            <div class="device-panel" v-for="(item, index) in form.contactsInfoList" :key="index">
              <el-form-item
                :label="index === 0 ?  '客户联系人' : ''"
                :prop="`contactsInfoList.${index}.name`"
                :rules="[{ required: true, message: '请输入联系人名称', trigger: 'blur' }]"
              >
                <v-input
                  v-model="item.name"
                  placeholder="请输入姓名"
                  :width="120"
                />
              </el-form-item>
              <el-form-item
                label-width="20px"
                :prop="`contactsInfoList.${index}.duty`"
              >
                <v-input v-model="item.duty" placeholder="请输入职务" :width="150" />
              </el-form-item>
              <el-form-item
                :prop="`contactsInfoList.${index}.mobileNum`"
                label-width="20px"
                :rules="[{validator: validatePhone1, trigger: 'blur'}]"
              >
                <v-input
                  v-model="item.mobileNum"
                  placeholder="请输入手机号"
                  :maxlength="11"
                  :width="182"
                />
              </el-form-item>
              <v-button
                v-if="form.contactsInfoList.length >= 1"
                style="margin:0 0 0 10px;"
                icon="el-icon-minus"
                type="danger"
                circle
                @click="removeDevice(index, form.contactsInfoList)"
              />
              <v-button
                v-if="index === 0"
                style="margin:0 0 0 10px;"
                icon="el-icon-plus"
                type="primary"
                circle
                @click="addDevice(form.contactsInfoList)"
              />
            </div>
            <el-form-item
              v-if="form.contactsInfoList.length === 0"
              label="客户联系人"
            >
              <v-button
                style="margin:0 0 0 10px;"
                icon="el-icon-plus"
                type="primary"
                circle
                @click="addDevice(form.contactsInfoList)"
              />
            </el-form-item>
          </col2-item>
        </col2-block>
        <col2-block title="业务信息">
          <col2-item class="f-wh">
            <el-form-item
              label="所属公司"
              prop="orgId"
              :rules="[{required: true, message: '请选择所属公司', trigger: 'change'}]"
            >
              <v-select2
                ref="regionNameRef"
                v-model="form.orgId"
                placeholder="输入公司名称"
                v-bind="regionParams"
                :width="180"
              />
            </el-form-item>
            <el-form-item label="客户等级" prop="grade">
              <v-select v-model="form.grade" :options="customerGradeOps" :width="width"/>
            </el-form-item>
            <el-form-item label="业务类型" prop="businessType">
              <v-select v-model="form.businessType" :options="customerBusinessTypeOps" :width="width"/>
            </el-form-item>
            <el-form-item label="我方负责人" prop="director">
              <v-input
                v-model="form.director"
                placeholder="请输入我方负责人"
                :maxlength="15"
                :width="182" />
            </el-form-item>
            <el-form-item label="合作状态" prop="cooperatingState"
              :rules="[{required: true, message: '请选择合作状态', trigger: 'change'}]"
            >
              <v-select v-model="form.cooperatingState" :options="customerCooperatingStateOps" :width="width"/>
            </el-form-item>
          </col2-item>
          <col2-item class="f-wh">
            <el-form-item label="客户来源" prop="source">
              <v-select v-model="form.source" :options="customerSourceOps" :width="width"/>
            </el-form-item>
            <el-form-item label="资质评级" prop="qualificationLevel">
              <v-select v-model="form.qualificationLevel" :options="customerQualificationLevelOps" :width="width"/>
            </el-form-item>
            <el-form-item label="是否战略客户" prop="isStrategic">
              <v-select v-model="form.isStrategic" :options="yesOrNoOps" :width="width"/>
            </el-form-item>
            <el-form-item label="负责人手机号" prop="directorMobileNum"
              :rules="[{validator: validatePhone, trigger: 'blur'}]"
            >
               <v-input v-model="form.directorMobileNum" placeholder="请输入负责人手机号" :width="182" />
            </el-form-item>
          </col2-item>
          <col2-item :span="24" v-if="form.orgId">
            <el-form-item label="上级客户" prop="parentCustomer">
              <chosenListPanel :list.sync="orgList" @select="toSelect"/>
            </el-form-item>
          </col2-item>
        </col2-block>
        <col2-block title="公司账户信息">
          <col2-item class="f-wh">
            <el-form-item label="税号" prop="taxNumber">
               <v-input v-model="form.taxNumber" placeholder="请输入税号" :width="182" />
            </el-form-item>
            <el-form-item label="开户银行" prop="openingBank">
               <v-input v-model="form.openingBank" placeholder="请输入开户银行" :width="182" />
            </el-form-item>
          </col2-item>
          <col2-item class="f-wh">
            <el-form-item label="购方银行帐号" prop="bankAccount">
               <v-input v-model="form.bankAccount" placeholder="请输入购方银行帐号" :width="182" />
            </el-form-item>
          </col2-item>
        </col2-block>
        <col2-block title="其它信息">
          <col2-item :span="24">
            <el-form-item label="备注" prop="remark">
              <v-textarea v-model="form.remark" :width="width2" :maxlength="200" />
            </el-form-item>
          </col2-item>
        </col2-block>
      </col2-detail>
    </form-panel>
    <multi-select
      ref="multiSelect"
      title="客户列表"
      :isShow.sync="isShow"
      :searchUrl="getSuperiorCustomerListURL"
      :extraParams="extraParams"
      :headers="orgTableHeader"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="orgList"
      @callback="onOrgSelect"
    >
      <template #searchSlot>
        <v-input v-model="searchParams.orgName" label="客户名称"></v-input>
        <v-select
          v-model="searchParams.cooperatingState"
          label="客户状态"
          :options="customerCooperatingStateAllOps"
          :width="width"
        />
      </template>
    </multi-select>
  </div>
</template>

<script>
import {
  getDetailInitInfoURL,
  saveCustomerInfoURL,
  uploadURL,
  getChinaAreaTreeURL,
  getDetailRecordInfoURL,
  getSuperiorCustomerListURL
} from './api'
import {
} from './map'
import {
  Col2Detail,
  Col2Block,
  Col2Item,
  ChosenListPanel,
  MultiSelect,
  AreaSelect
} from 'components/bussiness'
import { vUploader2 } from 'components/control'
import { mobileRegular, emailAddressRegular } from 'common/regular'
import { tenantParams } from 'common/select2Params'

export default {
  name: 'customerManageForm',
  components: {
    Col2Detail,
    Col2Block,
    Col2Item,
    ChosenListPanel,
    MultiSelect,
    AreaSelect,
    vUploader2
    // vUploader
  },
  data () {
    return {
      width: 182,
      width2: 535,
      orgType: undefined, // 组织类型
      uploadURL,
      config: {
        submitUrl: saveCustomerInfoURL,
        submitMethod: 'POST',
        queryUrl: `${getDetailRecordInfoURL}/${this.$route.query.id}`
      },
      form: {
        name: '',
        type: undefined,
        number: '',
        parentCustomer: '', // 上级客户
        mail: '',
        postCode: '',
        city: '',
        area: '',
        province: '',
        address: '',
        introduction: '',
        fileUrlList: [],
        contactsInfoList: [],
        orgId: '',
        source: undefined,
        grade: undefined,
        qualificationLevel: undefined,
        businessType: undefined,
        director: '',
        isStrategic: undefined,
        directorMobileNum: '',
        cooperatingState: undefined,
        taxNumber: '',
        bankAccount: '',
        openingBank: '',
        remark: ''
      },
      /** select2 */
      regionParams: tenantParams,
      /* 级联选择器 */
      getChinaAreaTreeURL,
      areaList: [],
      /* 控件选项 */
      customerBusinessTypeOps: [],
      customerCooperatingStateOps: [],
      customerGradeOps: [],
      customerQualificationLevelOps: [],
      customerSourceOps: [],
      customerTypeOps: [],
      // entOrgOps: [],
      yesOrNoOps: [],
      /* 多选框控件 */
      getSuperiorCustomerListURL,
      isShow: false,
      searchParams: {
        name: '',
        cooperatingState: undefined
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      orgList: []
    }
  },
  computed: {
    isEdit () {
      return this.$route.query.id !== undefined
    },
    /* 全局变量 */
    userInfo () {
      return this.$store.state.userInfo
    },
    /* 表单提交and请求 */
    extraParams () {
      let params = { orgId: this.form.orgId }
      if (this.isEdit) {
        params = {
          orgId: this.form.orgId,
          selfCustomerId: this.$route.query.id
        }
      }
      return params
    },
    /* select2 控件 */
    customerCooperatingStateAllOps () {
      return [{ value: undefined, text: '全部' }, ...this.customerCooperatingStateOps]
    },
    /* 多选框 */
    orgTableHeader () { // 多选框表头
      return [
        {
          prop: 'name',
          label: '客户名称'
        },
        {
          prop: 'director',
          label: '负责人'
        },
        {
          prop: 'cooperatingState',
          label: '客户状态'
        }
      ]
    },
    responseParams () { // 多选框响应字段
      let responseParams = {
        id: 'id',
        name: 'name'
      }
      return responseParams
    }
  },
  watch: {
    orgList: {
      handler (val) {
        this.form.parentCustomer = val.map(v => `${v.id},${v.text}`).join(';')
        console.log(this.form, 'val')
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    const { id } = this.$route.query
    this.onOff = false
    if (id !== undefined) {
      this.onOff = true
      this.$refs.formPanel.getData()
    }
    this.$setBreadcrumb(`${id ? '编辑' : '新增'}客户`)
    this.getSelectList()
  },
  methods: {
    update (data) { // 更新数据
      Object.keys({ ...this.form, id: '' }).forEach(key => {
        this.form[key] = data[key] || this.getEmptyValue(data[key], this.form[key])
      })
      let parentCustomer = data.parentCustomer
      let prevList = parentCustomer && parentCustomer.split(';').map(v => {
        let [id, name] = v.split(',')
        return {
          id,
          name
        }
      })
      this.orgList = prevList && prevList.length > 0
        ? prevList.map(item => ({
          text: item.name,
          id: item.id,
          obj: item
        })) : []
      this.areaList = [data.province, data.city, data.area]
      this.$nextTick(() => {
        this.onOff = false
      })
    },
    /* 接口类 */
    getSelectList () { // 其余下拉选项数据
      this.$axios.get(getDetailInitInfoURL).then((res) => {
        if (res.status === 100 && res.data) {
          const {
            customerBusinessTypeList,
            customerCooperatingStateList,
            customerGradeList,
            customerQualificationLevelList,
            customerSourceList,
            customerTypeList,
            // entOrgList,
            yesOrNoList
          } = res.data
          this.customerBusinessTypeOps = customerBusinessTypeList.map(item => ({ text: item, value: item }))
          this.customerCooperatingStateOps = customerCooperatingStateList.map(item => ({ text: item, value: item }))
          this.customerGradeOps = customerGradeList.map(item => ({ text: item, value: item }))
          this.customerQualificationLevelOps = customerQualificationLevelList.map(item => ({ text: item, value: item }))
          this.customerSourceOps = customerSourceList.map(item => ({ text: item, value: item }))
          this.customerTypeOps = customerTypeList.map(item => ({ text: item, value: item }))
          // this.entOrgOps = entOrgList.map(item => ({ text: item.text, value: item.id }))
          this.yesOrNoOps = yesOrNoList.map(item => ({ text: item, value: item }))
        }
      })
    },

    /* 其余控件方法 */
    async beforeRemove () { // 上传文件删除前
      console.log()
      const isOk = await this.$confirm('此操作将永久删除该文件, 是否继续？')
      return isOk
    },
    toSelect () { // 打开上级客户多选框
      this.isShow = true
    },
    areaChange (val) { // 联系地址
      if (val.area) {
        this.form.city = val.city.code
        this.form.area = val.area.code
        this.form.province = val.province.code
      } else {
        this.form.city = ''
        this.form.area = ''
        this.form.province = ''
      }
    },
    onOrgSelect (data) { // 组织多选框内容
      console.log(data, '多选框选择内容')
    },
    removeDevice (i, arr) {
      arr.splice(i, 1)
    },
    addDevice (arr) {
      arr.push({
        name: '',
        duty: '',
        mobileNum: ''
      })
    },

    /* 表单校验 */
    // valiAreaList (rule, value, callback) { // 联系地址
    //   setTimeout(() => {
    //     if (this.areaList.length > 0) {
    //       callback()
    //     } else {
    //       callback(new Error('请选择省市区'))
    //     }
    //   })
    // },
    validatePhone1 (rule, value, callback) { // 手机号验证
      if (value === '') {
        callback(new Error('请填写手机号'))
      } else if (!mobileRegular.test(value)) {
        callback(new Error('手机号格式不正确'))
      } else {
        callback()
      }
    },
    validatePhone (rule, value, callback) { // 手机号验证
      if (value === '') {
        callback()
        return
      }
      if (!mobileRegular.test(value)) {
        callback(new Error('手机号格式不正确'))
      } else {
        callback()
      }
    },
    validateNum (rule, value, callback) { // 验证合同编号
      let reg = /[\u4e00-\u9fa5]/
      if (value === '') {
        callback(new Error('请输入客户编号'))
      } else if (reg.test(value)) {
        callback(new Error('格式错误，必须为数字、英文或符号！'))
      } else {
        callback()
      }
    },
    validateEmail (rule, value, callback) {
      if (value === '') {
        callback()
        return
      }
      if (!emailAddressRegular.test(value)) {
        callback(new Error('请输入正确的邮箱地址！'))
      } else {
        callback()
      }
    },
    validateCode (rule, value, callback) {
      if (value === '') {
        callback()
        return
      }
      if (!/^\d{6}$/.test(value)) {
        callback(new Error('请输入正确的邮政编码！'))
      } else {
        callback()
      }
    },
    /** 辅助类方法 */
    getEmptyValue (val, emptyVal) { // 对空值进行判断
      if (val === undefined || val === '' || val === null) {
        return emptyVal
      } else if (val === 0 || val === '0') {
        return 0
      } else if (val === false) {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mar-l {
  margin-left: 8px;
}
.f-wh {
  width: 355px;
}
// .f-wh2 {
//   width: 355px;
// }
.customerManageForm-wrapper {
  .device-panel {
    display: inline-block;
    align-items: flex-start;
    display: flex;
  }
  .check-panel {
    display: flex;
    flex-flow: wrap;
    padding: 10px;
    .checkbox-wrapper {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}
</style>
