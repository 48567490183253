var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customerManageForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.config,
          false
        ),
        [
          _c(
            "col2-detail",
            { attrs: { span: 24 } },
            [
              _c(
                "col2-block",
                { attrs: { title: "客户基本信息" } },
                [
                  _c(
                    "col2-item",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "客户名称",
                            prop: "name",
                            rules: [
                              {
                                required: true,
                                message: "请输入客户名称",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入客户名称",
                              disabled: _vm.isEdit,
                              maxlength: 30,
                              width: _vm.width2,
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "col2-item",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "col2-item",
                        { staticClass: "f-wh" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "客户类型",
                                prop: "type",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择客户类型",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.customerTypeOps,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "type", $$v)
                                  },
                                  expression: "form.type",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "col2-item",
                        { staticClass: "f-wh" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "客户编号",
                                prop: "number",
                                rules: [
                                  {
                                    required: _vm.validateNum,
                                    message: "请输入客户编号",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input", {
                                attrs: {
                                  placeholder: "请输入客户编号",
                                  maxlength: 20,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.number,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "number", $$v)
                                  },
                                  expression: "form.number",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "col2-item",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "col2-item",
                        { staticClass: "f-wh" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "邮箱地址",
                                prop: "mail",
                                rules: [
                                  {
                                    validator: _vm.validateEmail,
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input", {
                                attrs: {
                                  placeholder: "请输入邮箱地址",
                                  maxlength: 30,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.mail,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "mail", $$v)
                                  },
                                  expression: "form.mail",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "col2-item",
                        { staticClass: "f-wh" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "邮政编码",
                                prop: "postCode",
                                rules: [
                                  {
                                    validator: _vm.validateCode,
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input", {
                                attrs: {
                                  placeholder: "请输入邮政编码",
                                  maxlength: 6,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.postCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "postCode", $$v)
                                  },
                                  expression: "form.postCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("col2-item", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "联系地址", prop: "area" } },
                          [
                            _c("area-select", {
                              attrs: {
                                codes: _vm.areaList,
                                "detail-addr": false,
                              },
                              on: {
                                "update:codes": function ($event) {
                                  _vm.areaList = $event
                                },
                                change: _vm.areaChange,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { "label-width": "20px", prop: "address" } },
                          [
                            _c("v-input", {
                              attrs: {
                                placeholder: "请输入详细地址",
                                maxlength: 30,
                                width: 340,
                              },
                              model: {
                                value: _vm.form.address,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "address", $$v)
                                },
                                expression: "form.address",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "col2-item",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户简介", prop: "introduction" } },
                        [
                          _c("v-textarea", {
                            attrs: { width: _vm.width2, maxlength: 100 },
                            model: {
                              value: _vm.form.introduction,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "introduction", $$v)
                              },
                              expression: "form.introduction",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "col2-item",
                    { staticStyle: { width: "720px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "附件", prop: "fileUrlList" } },
                        [
                          _c("v-uploader2", {
                            attrs: {
                              action: _vm.uploadURL,
                              filePath: "customerFiles",
                              tip: "可以添加客户相关资料文件，最多允许上传10个文件,单个文件大小不允许超过50MB",
                              limit: 10,
                              beforeRemove: _vm.beforeRemove,
                            },
                            model: {
                              value: _vm.form.fileUrlList,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "fileUrlList", $$v)
                              },
                              expression: "form.fileUrlList",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "col2-item",
                    { attrs: { span: 24 } },
                    [
                      _vm._l(_vm.form.contactsInfoList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "device-panel" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: index === 0 ? "客户联系人" : "",
                                  prop: `contactsInfoList.${index}.name`,
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入联系人名称",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("v-input", {
                                  attrs: {
                                    placeholder: "请输入姓名",
                                    width: 120,
                                  },
                                  model: {
                                    value: item.name,
                                    callback: function ($$v) {
                                      _vm.$set(item, "name", $$v)
                                    },
                                    expression: "item.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "20px",
                                  prop: `contactsInfoList.${index}.duty`,
                                },
                              },
                              [
                                _c("v-input", {
                                  attrs: {
                                    placeholder: "请输入职务",
                                    width: 150,
                                  },
                                  model: {
                                    value: item.duty,
                                    callback: function ($$v) {
                                      _vm.$set(item, "duty", $$v)
                                    },
                                    expression: "item.duty",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: `contactsInfoList.${index}.mobileNum`,
                                  "label-width": "20px",
                                  rules: [
                                    {
                                      validator: _vm.validatePhone1,
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("v-input", {
                                  attrs: {
                                    placeholder: "请输入手机号",
                                    maxlength: 11,
                                    width: 182,
                                  },
                                  model: {
                                    value: item.mobileNum,
                                    callback: function ($$v) {
                                      _vm.$set(item, "mobileNum", $$v)
                                    },
                                    expression: "item.mobileNum",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.form.contactsInfoList.length >= 1
                              ? _c("v-button", {
                                  staticStyle: { margin: "0 0 0 10px" },
                                  attrs: {
                                    icon: "el-icon-minus",
                                    type: "danger",
                                    circle: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeDevice(
                                        index,
                                        _vm.form.contactsInfoList
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            index === 0
                              ? _c("v-button", {
                                  staticStyle: { margin: "0 0 0 10px" },
                                  attrs: {
                                    icon: "el-icon-plus",
                                    type: "primary",
                                    circle: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addDevice(
                                        _vm.form.contactsInfoList
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      _vm.form.contactsInfoList.length === 0
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "客户联系人" } },
                            [
                              _c("v-button", {
                                staticStyle: { margin: "0 0 0 10px" },
                                attrs: {
                                  icon: "el-icon-plus",
                                  type: "primary",
                                  circle: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.addDevice(
                                      _vm.form.contactsInfoList
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "业务信息" } },
                [
                  _c(
                    "col2-item",
                    { staticClass: "f-wh" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属公司",
                            prop: "orgId",
                            rules: [
                              {
                                required: true,
                                message: "请选择所属公司",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                ref: "regionNameRef",
                                attrs: {
                                  placeholder: "输入公司名称",
                                  width: 180,
                                },
                                model: {
                                  value: _vm.form.orgId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "orgId", $$v)
                                  },
                                  expression: "form.orgId",
                                },
                              },
                              "v-select2",
                              _vm.regionParams,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户等级", prop: "grade" } },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.customerGradeOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.grade,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "grade", $$v)
                              },
                              expression: "form.grade",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务类型", prop: "businessType" } },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.customerBusinessTypeOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.businessType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "businessType", $$v)
                              },
                              expression: "form.businessType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "我方负责人", prop: "director" } },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入我方负责人",
                              maxlength: 15,
                              width: 182,
                            },
                            model: {
                              value: _vm.form.director,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "director", $$v)
                              },
                              expression: "form.director",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "合作状态",
                            prop: "cooperatingState",
                            rules: [
                              {
                                required: true,
                                message: "请选择合作状态",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.customerCooperatingStateOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.cooperatingState,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cooperatingState", $$v)
                              },
                              expression: "form.cooperatingState",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "col2-item",
                    { staticClass: "f-wh" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户来源", prop: "source" } },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.customerSourceOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.source,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "source", $$v)
                              },
                              expression: "form.source",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "资质评级",
                            prop: "qualificationLevel",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.customerQualificationLevelOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.qualificationLevel,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "qualificationLevel", $$v)
                              },
                              expression: "form.qualificationLevel",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "是否战略客户", prop: "isStrategic" },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.yesOrNoOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.isStrategic,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isStrategic", $$v)
                              },
                              expression: "form.isStrategic",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "负责人手机号",
                            prop: "directorMobileNum",
                            rules: [
                              { validator: _vm.validatePhone, trigger: "blur" },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入负责人手机号",
                              width: 182,
                            },
                            model: {
                              value: _vm.form.directorMobileNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "directorMobileNum", $$v)
                              },
                              expression: "form.directorMobileNum",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.orgId
                    ? _c(
                        "col2-item",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "上级客户",
                                prop: "parentCustomer",
                              },
                            },
                            [
                              _c("chosenListPanel", {
                                attrs: { list: _vm.orgList },
                                on: {
                                  "update:list": function ($event) {
                                    _vm.orgList = $event
                                  },
                                  select: _vm.toSelect,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "公司账户信息" } },
                [
                  _c(
                    "col2-item",
                    { staticClass: "f-wh" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "税号", prop: "taxNumber" } },
                        [
                          _c("v-input", {
                            attrs: { placeholder: "请输入税号", width: 182 },
                            model: {
                              value: _vm.form.taxNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "taxNumber", $$v)
                              },
                              expression: "form.taxNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "开户银行", prop: "openingBank" } },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入开户银行",
                              width: 182,
                            },
                            model: {
                              value: _vm.form.openingBank,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "openingBank", $$v)
                              },
                              expression: "form.openingBank",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "col2-item",
                    { staticClass: "f-wh" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "购方银行帐号", prop: "bankAccount" },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入购方银行帐号",
                              width: 182,
                            },
                            model: {
                              value: _vm.form.bankAccount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "bankAccount", $$v)
                              },
                              expression: "form.bankAccount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "其它信息" } },
                [
                  _c(
                    "col2-item",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注", prop: "remark" } },
                        [
                          _c("v-textarea", {
                            attrs: { width: _vm.width2, maxlength: 200 },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("multi-select", {
        ref: "multiSelect",
        attrs: {
          title: "客户列表",
          isShow: _vm.isShow,
          searchUrl: _vm.getSuperiorCustomerListURL,
          extraParams: _vm.extraParams,
          headers: _vm.orgTableHeader,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.orgList,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            _vm.orgList = $event
          },
          "update:back-fill": function ($event) {
            _vm.orgList = $event
          },
          callback: _vm.onOrgSelect,
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "客户名称" },
                  model: {
                    value: _vm.searchParams.orgName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "orgName", $$v)
                    },
                    expression: "searchParams.orgName",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "客户状态",
                    options: _vm.customerCooperatingStateAllOps,
                    width: _vm.width,
                  },
                  model: {
                    value: _vm.searchParams.cooperatingState,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "cooperatingState", $$v)
                    },
                    expression: "searchParams.cooperatingState",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }